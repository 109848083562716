






































































































































































































































































































































































.position-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem 0.625rem;
  .position {
    margin-top: 4rem;
    width: 100%;
    max-width: 1368px;
    position: relative;
    padding: 1.25rem;
    @media (max-width: 480px) {
      padding: 0px;
    }
    .position-header {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      margin-top: 22px;
      .slots-path {
        background-image: linear-gradient(
          to right,
          rgba(105, 179, 1, 0.4),
          rgba(85, 89, 102, 0.4) 25%,
          rgba(85, 89, 102, 0.4)
        );
        display: inline-flex;
        padding: 0px 2rem;
        height: 2.5rem;
        line-height: 2.5rem;
        border-radius: 1.25rem;
        @media (max-width: 480px) {
          padding: 0px 1rem;
          height: 1.5rem;
          font-size: 12px;
        }
        .slot {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          .active {
            color: rgb(255, 255, 255);
            font-weight: 800;
          }
        }
      }
      .select {
        min-width: 200px;
        height: 2.5rem;
        margin-left: 1.875rem;
        color: rgba(153, 164, 176, 0.6);
        @media (max-width: 480px) {
          height: 1.5rem;
          font-size: 12px;
        }
        .select-trigger {
          position: relative;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          overflow: hidden;
          height: 100%;
          cursor: pointer;
          padding: 0px 1.25rem;
          user-select: none;
          border-radius: 1.25rem;
          background-color: rgba(49, 52, 60, 0.5);
          .provider-label {
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .arrow {
            width: 2.5rem;
            height: 100%;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            position: absolute;
            right: 0px;
            top: 0px;
            svg {
              font-size: 0.625rem;
              transition: transform 0.5s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
              color: rgba(153, 164, 176, 0.6);
            }
          }
        }
        .listTypeGame {
          position: absolute;
          top: 85px;
          width: 200px;
          padding: 10px 10px;
          border-radius: 1.25rem;
          background-color: rgb(27, 29, 33);
          z-index: 10;
          @media (max-width: 480px) {
            top: 50px;
          }
          p {
            &:hover {
              background-color: rgb(45, 48, 53);
              color: #fff;
              width: 100%;
              padding: 5px 10px;
              cursor: pointer;
              border-radius: 20px;
              margin-bottom: 5px;
            }
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 480px) {
              font-size: 12px;
            }
            &.activeType {
              border: 1px solid rgb(123, 197, 20);
              border-radius: 20px;
              padding: 5px 10px;
            }
            span {
              color: rgb(123, 197, 20);
              &.active {
                background-color: rgb(93, 160, 0);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: relative;
                &::after {
                  content: '';
                  position: absolute;
                  width: 1.125rem;
                  height: 1.125rem;
                  left: -0.3125rem;
                  top: -0.3125rem;
                  background-color: rgba(144, 155, 135, 0.15);
                  border-radius: 1.125rem;
                }
              }
            }
          }
        }
      }
    }
    .position-content {
      width: 100%;
      .game-good {
        width: 100%;
        .game-good-title {
          width: 100%;
          margin: 28px 0px 12px 10px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          color: rgb(153, 164, 176);
          @media (max-width: 480px) {
            margin: 28px 0px 12px 0px;
          }
          .tit {
            color: rgb(255, 255, 255);
            font-size: 1rem;
            font-weight: 800;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            @media (max-width: 480px) {
              font-size: 12px;
            }
            .cont {
              @media (max-width: 375px) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;
              }
            }
            svg {
              margin-right: 0.5rem;
              color: rgb(247, 168, 0);
            }
            .total {
              color: rgb(93, 160, 0);
              margin-left: 0.875rem;
            }
          }
          .tit-new {
            color: rgb(255, 255, 255);
            font-size: 1rem;
            font-weight: 800;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            @media (max-width: 480px) {
              font-size: 12px;
            }
            .cont {
              @media (max-width: 375px) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;
              }
            }
            svg {
              margin-right: 0.5rem;
              color: rgb(227, 77, 190);
            }
            .total {
              color: rgb(93, 160, 0);
              margin-left: 0.875rem;
            }
          }
          .tit-hot {
            color: rgb(255, 255, 255);
            font-size: 1rem;
            font-weight: 800;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            @media (max-width: 480px) {
              font-size: 12px;
            }
            .cont {
              @media (max-width: 375px) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;
              }
            }
            svg {
              margin-right: 0.5rem;
              color: rgb(239, 119, 26);
            }
            .total {
              color: rgb(93, 160, 0);
              margin-left: 0.875rem;
            }
          }
          .page-wrap {
            flex: 1 1 auto;
            display: flex;
            -webkit-box-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            align-items: center;
            .prev-button {
              width: 2.125rem;
              height: 2rem;
              background-color: rgb(30, 32, 36);
              border-top-left-radius: 1rem;
              border-bottom-left-radius: 1rem;
              margin: 0px;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              color: rgba(153, 164, 176, 0.6);
              border: none;
              margin-right: 5px;
              @media (max-width: 480px) {
                width: 1.5rem;
                height: 1.5rem;
              }
              &:hover {
                color: #fff;
                background-color: rgba(75, 72, 72, 0.719);
              }
            }
            .next-button {
              width: 2.125rem;
              height: 2rem;
              background-color: rgb(30, 32, 36);
              border-top-right-radius: 1rem;
              border-bottom-right-radius: 1rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              color: rgba(153, 164, 176, 0.6);
              border: none;
              @media (max-width: 480px) {
                width: 1.5rem;
                height: 1.5rem;
              }
              &:hover {
                color: #fff;
                background-color: rgba(75, 72, 72, 0.719);
              }
            }
          }
        }
      }
      .gameList {
        width: 100%;
        .gameList-title {
          width: 100%;
          margin: 28px 0px 12px 10px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          color: rgb(153, 164, 176);
          .tit {
            color: rgb(255, 255, 255);
            font-size: 1rem;
            font-weight: 800;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            @media (max-width: 480px) {
              font-size: 12px;
            }
            .cont {
              @media (max-width: 375px) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;
              }
            }
            svg {
              margin-right: 0.5rem;
              color: rgb(93, 160, 0);
            }
            .total {
              color: rgb(93, 160, 0);
              margin-left: 0.875rem;
            }
          }
        }
        .gameList-list {
          margin-top: 32px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          width: 100%;
          .gameItem {
            flex: 1 1 auto;
            width: 15%;
            margin-right: 0.8%;
            margin-left: 0.8%;
            @media (max-width: 1699px) {
              width: 18%;
              max-width: 18%;
            }
            @media (max-width: 1499px) {
              width: 23%;
              max-width: 23%;
            }
            @media (max-width: 1099px) {
              width: 31%;
              max-width: 31%;
            }
            @media (max-width: 991px) {
              width: 23%;
              max-width: 23%;
            }
            @media (max-width: 767px) {
              width: 31%;
              max-width: 31%;
            }
            @media (max-width: 480px) {
              width: 48%;
              max-width: 48%;
            }
          }
          .gameList-btnMore-box {
            width: 100%;
            display: flex;
            justify-content: center;
            .gameList-btnMore {
              // margin: 1.5625rem auto;
              width: 12.5rem;
              .btnMore-info {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                margin-bottom: 1.0625rem;
                color: rgba(153, 164, 176, 0.8);

                .current {
                  color: rgb(255, 255, 255);
                }
              }
              .btnMore-load {
                background-color: rgb(49, 52, 58);
                width: 10rem;
                height: 3rem;
                line-height: 3rem;
                text-align: center;
                font-weight: 400;
                margin: 0px auto;
                color: rgba(153, 164, 176, 0.6);
                box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
                display: block;
                border-radius: 6.25rem;
                cursor: pointer;
                transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1)
                  0s;
                border: none;
                .button-inner {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .partner {
        width: 100%;
        .partner-title {
          width: 100%;
          margin: 28px 0px 12px 10px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          color: rgb(153, 164, 176);
          .tit {
            color: rgb(255, 255, 255);
            font-size: 1rem;
            font-weight: 800;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            @media (max-width: 480px) {
              font-size: 12px;
            }
            .cont {
              @media (max-width: 375px) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;
              }
            }
            svg {
              margin-right: 0.5rem;
              color: rgb(247, 168, 0);
            }
            .total {
              color: rgb(93, 160, 0);
              margin-left: 0.875rem;
            }
          }
          .page-wrap {
            flex: 1 1 auto;
            display: flex;
            -webkit-box-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            align-items: center;
            .prev-button {
              width: 2.125rem;
              height: 2rem;
              background-color: rgb(30, 32, 36);
              border-top-left-radius: 1rem;
              border-bottom-left-radius: 1rem;
              margin: 0px;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              color: rgba(153, 164, 176, 0.6);
              border: none;
              margin-right: 5px;
              &:hover {
                color: #fff;
                background-color: rgba(75, 72, 72, 0.719);
              }
            }
            .next-button {
              width: 2.125rem;
              height: 2rem;
              background-color: rgb(30, 32, 36);
              border-top-right-radius: 1rem;
              border-bottom-right-radius: 1rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              color: rgba(153, 164, 176, 0.6);
              border: none;
              &:hover {
                color: #fff;
                background-color: rgba(75, 72, 72, 0.719);
              }
            }
          }
        }
        .partner-slide {
          .partner-item {
            border-radius: 20px;
            a {
              display: block;
              background-image: url('~@/assets/images/home/partner.png');
              background-repeat: no-repeat;
              background-color: rgb(49, 52, 58);
              height: 3.5rem;
              background-size: cover;
              background-position: center;
              cursor: pointer;
              margin-right: 3px;
              border-radius: 10px;
              @media (max-width: 1299px) {
                height: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
}
